@import "@vahak/core/dist/scss/variables";
@import "@vahak/core/dist/scss/functions";

.bidFormWrapper {
    .bidFormFlexContainer {
        .loadDetailsSection {
            //
        }
        .loadDetailsSectionWithAssuredHighlightForMobile {
            @extend .loadDetailsSection;
        }
    }
}
//Desktop Screen only
@media (min-width: $desktopBreakpoint) {
    .bidFormWrapper {
        position: relative;
        .bidModalClose {
            // position: absolute;
            // right: 20px;
            // top: 20px;

            margin: 32px;
            height: fit-content;
        }
        .bidFormFlexContainer {
            .loadDetailsSection {
                background: #f7f7f7;
                padding: 52px 20px 0 20px;

                //Temp style
                min-height: 500px;
                min-width: 250px;
                max-width: 380px;
            }

            .bidForm {
                padding: 60px 0 30px 52px;

                .formFieldsWrapper {
                    flex: auto;
                }
            }
        }
    }
}

// Mid screen
@media (min-width: $mobileBreakpoint) and (max-width: $desktopBreakpoint) {
    .bidFormWrapper {
        position: relative;
        .bidModalClose {
            // position: absolute;
            // right: 20px;
            // top: 20px;

            margin: 32px;
            height: fit-content;
        }
        .bidFormFlexContainer {
            .loadDetailsSection {
                background: #f7f7f7;
                padding: 52px 20px 0 20px;

                //Temp style
                min-height: 500px;
                min-width: 250px;
                max-width: 380px;
            }

            .bidForm {
                padding: 100px 0 45px 52px;

                .formFieldsWrapper {
                    flex: auto;
                }
            }
        }
    }
}

//Small Screen only
@media (max-width: $mobileBreakpoint) {
    .bidFormWrapper {
        height: 100%;
        overflow: scroll;
        .bidModalClose {
            display: none;
        }

        .sheetHeader {
            background-color: #ffffff;
            padding: 12px 12px 10px 12px;
            position: fixed;
            z-index: 5;
            width: 100%;
            top: 0;
            border-top-left-radius: 8px;
            border-top-right-radius: 8px;
        }
        .bidFormFlexContainer {
            flex-direction: column;
            margin-top: 70px;
            padding: 16px;

            .loadDetailsSection {
                background: #ffffff;
                padding: 16px;
                border: 2px solid #e3e3e3;
                border-radius: 8px;
            }
            .loadDetailsSectionWithAssuredHighlightForMobile {
                position: relative;
                margin-top: 20px;
                width: 100%;
            }
        }
    }
}
