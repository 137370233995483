@import "@vahak/core/dist/scss/variables";
@import "@vahak/core/dist/scss/functions";

.listItem {
    display: inline-flex;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 16px;
    font-size: calcRem(14);

    .count {
        flex-shrink: 0;
        display: inline-flex;
        justify-content: center;
        align-items: center;

        color: $white;
        background-color: $blue-1000;
        border-radius: 50%;
        width: 22px;
        height: 22px;
    }
    .content {
        // margin-top: 2px;
    }
}
.main {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .list {
        width: 500px;
        gap: 12px;
    }
}

//Small Screen only
@media (max-width: $mobileBreakpoint) {
    .listItem {
        font-size: calcRem(13);
    }
    .main {
        gap: 10px;

        .list {
            gap: 5px;
            width: 100%;
        }
    }
}
