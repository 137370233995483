@import "@vahak/core/dist/scss/variables";
@import "@vahak/core/dist/scss/functions";

.buttonWrapper {
    width: 100%;
    display: flex;
    gap: 20px;

    [data-chip-btn] {
        height: 55px;
        padding-inline: calcRem(46);
        display: inline-flex;
        align-items: center;
        justify-content: center;
    }
    [data-chip-btn--yes] {
        width: 100%;
    }
}

//Small Screen only
@media (max-width: $mobileBreakpoint) {
    .buttonWrapper {
        gap: 12px;
        [data-chip-btn] {
            padding-inline: calcRem(32);
        }
    }
}
