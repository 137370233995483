@import "@vahak/core/dist/scss/variables";
@import "@vahak/core/dist/scss/functions";

.main {
    .label {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 12px;
    }
    .userInfoWrapper {
        padding-top: 36px;
        padding-bottom: 75px;
    }
}

//Desktop Screen only
@media (min-width: $desktopBreakpoint) {
}

// Mid screen
@media (min-width: $mobileBreakpoint) and (max-width: $desktopBreakpoint) {
}

//Small Screen only
@media (max-width: $mobileBreakpoint) {
    .main {
        .label {
            .instantBookingTag {
                margin-left: -17px;
            }
            label {
                display: none;
            }
        }

        .userInfoWrapper {
            padding: 0;
        }
    }
}
