@import "@vahak/core/dist/scss/variables";
@import "@vahak/core/dist/scss/functions";

.main {
    font-family: var(--font-medium);
    display: flex;
    align-items: center;
    gap: 2px;

    font-size: calcRem(13);
    color: $green-700;

    svg {
        width: 30px;
        height: 30px;
    }
}

//Desktop Screen only
@media (min-width: $desktopBreakpoint) {
}

// Mid screen
@media (min-width: $mobileBreakpoint) and (max-width: $desktopBreakpoint) {
}

//Small Screen only
@media (max-width: $mobileBreakpoint) {
    .main {
        background-color: $green-100;
        font-size: calcRem(12);
        border-radius: 8px;
        padding: 4px 16px;

        svg {
            width: 34px;
            height: 34px;
        }
    }
}
